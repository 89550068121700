<template>
	<CoverageTemplate :page-title="$t('title')" full-width>
		<BRow>
			<BCol lg="12" md="10" sm="12">
				<BaseCard :title="$t('body.headingOne')">
					<p>{{ $t('body.bodyOne') }}</p>
					<p class="list-header">{{ $t('body.bulletHeader') }}</p>
					<ul>
						<li>{{ $t('body.bulletOne') }}</li>
						<li>{{ $t('body.bulletTwo') }}</li>
						<li>{{ $t('body.bulletThree') }}</li>
						<li>{{ $t('body.bulletFour') }}</li>
					</ul>
					<p>{{ $t('body.bodyOnePartTwo') }}</p>
					<h3>{{ $t('body.headingTwo') }}</h3>
					<p class="content">{{ $t('body.bodyTwo') }}</p>
					<BaseButton
						:label="$t('body.linkText')"
						:aria-label="$t('body.linkText')"
						:icon="['fal', 'external-link-alt']"
						variant="primary"
						:automation-id="getAutomationId('efap')"
						@click="navigateToEfap"
					/>
				</BaseCard>
			</BCol>
		</BRow>
	</CoverageTemplate>
</template>

<script>
import CoverageTemplate from './CoverageTemplate.vue';
import { BCol, BRow } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';

export default {
	name: 'EmployeeAndFamilyAssistance',
	components: {
		CoverageTemplate,
		BCol,
		BRow,
		BaseCard,
		BaseButton
	},
	mixins: [IdMixin, BreadcrumbsManager],
	computed: {
		locale() {
			return this.$root.$i18n.locale;
		}
	},
	async created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.efap', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.efap', 'fr') }
			]
		);
	},
	methods: {
		navigateToEfap() {
			const { brand } = JSON.parse(sessionStorage.getItem('securityPolicy'));
			if (brand === 'OBC' || brand === 'QBC') {
				if (this.locale === 'en') {
					window.location.href = 'https://inkblottherapy.com/inconfidencepersonal';
				} else {
					window.location.href = 'https://inkblottherapy.com/enequilibrepersonnel';
				}
			} else {
				if (this.locale === 'en') {
					window.location.href = 'https://www.medaviebc.ca/en/members/your-coverage/inconfidence';
				} else {
					window.location.href =
						'https://www.medaviebc.ca/fr/adherents/votre-couverture/enequilibre';
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.access {
	font-family: $josefin-font-family;
}
.content {
	width: 90%;
}
.list-header {
	margin-bottom: 0em;
}
</style>
<i18n>
{
  "en": {
    	"breadcrumb": {
			"home": "Home",
			"myCoverage": "Plan Coverage",
			"efap": "EFAP"
   		},
		"title": "inConfidence",
		"body": {
			"headingOne": "inConfidence (EFAP)",
			"bodyOne": "inConfidence is our secure and confidential assistance program for you and your family members, provided at no extra cost to you.",
			"bodyOnePartTwo": "inConfidence gives you the tools and support to lead a happier, healthier, more productive life at work and at home. ",
			"bulletHeader": "inConfidence includes:",
			"bulletOne": "24/7 access to in-person, video or telephone counselling",
			"bulletTwo": "Life, health and career coaching",
			"bulletThree": "Legal and financial consultations",
			"bulletFour": "Online resources on a wide variety of topics.",
			"headingTwo": "How to use inConfidence",
			"bodyTwo": "You can get started by signing up with our inConfidence partner, Inkblot, or find information and answers to common questions at the link below.",
			"linkText":"Go to inConfidence"
		}
  },
  "fr": {
    	"breadcrumb": {
    		"home": "Accueil",
    		"myCoverage": "Couverture du régime",
			"efap": "PAEF"
   		},
		"title": "enÉquilibre",
		"body": {
			"headingOne": "enÉquilibre (PAEF)",
			"bodyOne": "enÉquilibre est notre programme d’aide sécuritaire et confidentiel qui vous est offert sans frais, à vous et aux membres de votre famille.",
			"bodyOnePartTwo": "enÉquilibre vous offre les outils et le soutien nécessaires pour mener une vie professionnelle et personnelle à la fois heureuse, épanouie et productive. ",
			"bulletHeader": "Il vous donne accès à :",
			"bulletOne": "Des consultations en personne, par vidéo ou au téléphone, 24 h sur 24, sept jours sur sept",
			"bulletTwo": "Un accompagnement personnel, professionnel et en santé",
			"bulletThree": "Des consultations juridiques et financières",
			"bulletFour": "Des ressources en ligne sur des sujets variés",
			"headingTwo": "Comment vous démarrer avec enÉquilibre",
			"bodyTwo": "Pour commencer à utiliser enÉquilibre, inscrivez-vous ou ouvrez une session à Inkblot, notre partenaire en matière de consultation. C’est gratuit, sécuritaire et confidentiel. ",
			"linkText":"Rendez-vous sur le site d’enÉquilibre "
		}
 }
}
</i18n>
