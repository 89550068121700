<template>
	<CoverageTemplate v-if="!$store.state.loading" :page-title="$t('title')">
		<BaseCard>
			<h2>{{ $t('exclusion.title') }}</h2>

			<p>{{ $t('exclusion.description1') }}</p>
			<p>{{ $t('exclusion.description2') }}</p>

			<BRow v-if="tableItems.length > 0">
				<BCol cols="12" md="8">
					<BaseTable class="mb-0" :items="tableItems" stacked="sm" caption-top>
						<template #table-caption>
							<h2 class="table-title">{{ $t('exclusionTable.title') }}</h2>
						</template>
					</BaseTable>
				</BCol>
			</BRow>
		</BaseCard>
	</CoverageTemplate>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
// mixins
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
// components
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseTable from '@/components/common/base/BaseTable';
// pages
import CoverageTemplate from '@/pages/coverage/CoverageTemplate.vue';
// models
import Dependent from '@/models/Dependent.js';
// routes
import { COVERAGE, DASHBOARD } from '@/constants/Routes.js';
// utilities
import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';

export default {
	components: {
		BRow,
		BCol,
		CoverageTemplate,
		BaseCard,
		BaseTable
	},
	mixins: [BreadcrumbsManager],
	data() {
		return {
			participants: []
		};
	},
	computed: {
		tableItems() {
			const items = [];
			this.participants.data
				?.filter((person) => person.medicalExclusion === true)
				.forEach((person) => {
					const item = {};
					item[this.$t('exclusionTable.name')] = `${person.firstName} ${person.lastName}`;
					item[this.$t('exclusionTable.dob')] = format(
						new Date(
							person.dateOfBirth.substr(0, 4),
							person.dateOfBirth.substr(4, 2) - 1,
							person.dateOfBirth.substr(6, 2)
						),
						'dd MMM yyyy',
						{
							locale: this.$root.$i18n.locale === 'en' ? enCA : frCA
						}
					);
					items.push(item);
				});
			return items;
		}
	},
	async created() {
		this.$store.dispatch('updateLoading', true);
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.medicalExlusion', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.medicalExlusion', 'fr') }
			]
		);
		this.participants = await Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		this.$store.dispatch('updateLoading', false);
	}
};
</script>

<style lang="scss" scoped>
h2 {
	font-size: 24px;
}
.table-title {
	font-size: 21px;
	color: $black;
	line-height: normal;
	margin-bottom: 0;
}
</style>

<i18n>
{
	"en": {
		"title": "Medical Exclusion",
		"exclusion": {
			"title": "Some Medical Exclusions Apply",
			"description1": "Some individuals on your plan have medical exclusions that apply to their coverage. These exclusions would have been communicated before your plan started.",
			"description2": "Medical exclusions refer to anything on your plan that won't be covered due to a medical condition that existed before your plan started. This includes any treatments or medications related to that condition."
		},
		"exclusionTable": {
			"title": "The following people have exclusions:",
			"name": "Name",
			"dob": "Date of Birth"
		},
		"breadcrumb": {
			"home": "Home",
			"myCoverage": "Plan Coverage",
			"medicalExlusion": "Medical Exclusion"
		}
	},
	"fr": {
		"title": "Exclusions médicales",
		"exclusion": {
			"title": "Certaines exclusions médicales s'appliquent",
			"description1": "Certaines personnes couvertes par votre régime font l'objet d'exclusions médicales. Ces exclusions vous sont habituellement communiquées avant l'entrée en vigueur de votre régime.",
			"description2": "Nous entendons par exclusions médicales tout élément de votre régime qui ne sera pas couvert en raison d'un problème de santé qui était présent avant l'entrée en vigueur de votre régime. Les exclusions comprennent tous les traitements et les médicaments associés à ce problème de santé."
		},
		"exclusionTable": {
			"title": "Les personnes suivantes font l'objet d'exclusions médicales :",
			"name": "Nom",
			"dob": "Date de naissance"
		},
		"breadcrumb": {
			"home": "Accueil",
			"myCoverage": "Couverture du régime",
			"medicalExlusion": "Exclusions médicales"
		}
	}
}
</i18n>
