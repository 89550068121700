<template>
	<CoverageTemplate :page-title="$t('title')" full-width>
		<BRow>
			<BCol md="10" sm="12">
				<BaseCard :title="onlineDoctorInfo.coverageDescriptionTitle">
					<BCardText>
						{{ onlineDoctorInfo.coverageDescription }} <br />
						<br />
						<b class="access">{{ onlineDoctorInfo.accountAccessTitle }}</b>
						<br />
						{{ $t('body.firstBody.visit') }}
						<BLink :href="onlineDoctorInfo.accountAccessUrl" target="_blank"
							><b>{{ onlineDoctorInfo.accountAccessUrl }}</b>
							<FontAwesomeIcon
								:icon="['fal', 'external-link-alt']"
								icon-size="sm"
								aria-hidden="true"
						/></BLink>
						{{ $t('body.firstBody.sign') }}
					</BCardText>
				</BaseCard>
			</BCol>
		</BRow>
	</CoverageTemplate>
</template>

<script>
import CoverageTemplate from './CoverageTemplate.vue';
import Benefits from '@/models/coverage/BenefitsScepter';
import { BCol, BRow } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import { BCardText, BLink } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default {
	name: 'OnlineDoctorsPage',
	components: {
		CoverageTemplate,
		BCol,
		BRow,
		BaseCard,
		BCardText,
		BLink
	},
	mixins: [IdMixin, BreadcrumbsManager],
	data() {
		return {
			onlineDoctorInfo: {}
		};
	},
	computed: {
		locale() {
			return this.$root.$i18n.locale;
		}
	},
	watch: {
		locale() {
			this.getOnlineDoctors();
		}
	},
	async created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.onlineDoctors', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.onlineDoctors', 'fr') }
			]
		);
		this.getOnlineDoctors();
	},
	methods: {
		async getOnlineDoctors() {
			startSpinner();

			const response = await Benefits.getOnlineDoctors(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale
			);
			// If the response returned was valid then display the response to the user, if not then display the API error to the specific inputs.
			if (response?.data) {
				this.onlineDoctorInfo = response.data;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
			// Turn off the loading modal.
			stopSpinner();
		}
	}
};
</script>
<style lang="scss" scoped>
.access {
	font-family: $josefin-font-family;
}
</style>
<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
			"onlineDoctors": "Online Doctors"
    },
	"title": "Online Doctors",
	"body": {
		"firstBody": {
			"visit": "Visit",
			"sign": " to sign in, or to sign up for an account."
		}
	}
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
			"onlineDoctors": "Médecin en ligne"
    },
	"title": "Médecin en ligne",
	"body": {
		"firstBody": {
			"visit": "Visitez le",
			"sign": " pour ouvrir une session ou pour vous créer un compte."
		}
  	}
   }
}
</i18n>
